<template>
  <div v-loading.fullscreen.lock="isLoading">
    <el-card shadow="always">  
      <div slot="header">
        <span class="title">編輯群組功能</span>
      </div>

     <el-form
      ref="editForm"
      :rules="rules"
      :model="serviceGroupTeam"
      label-width="100px"
    >
      <el-form-item label="群組名稱" prop="name">
        <el-col :span="12">
          <el-input
            v-model="serviceGroupTeam.name"
            placeholder="請輸入群組名稱"
          ></el-input>
        </el-col>
      </el-form-item>

      <br />
      <el-form-item label-width="0">
        <h5><span>功能設定</span></h5>
        <CRow class="row justify-content-center">
          <CCol col="2" class="border border-secondary ccol-header-center">
            <span>模組</span>
          </CCol>
          <CCol col="4" class="border border-secondary ccol-header-center">
            <span>功能名稱</span>
          </CCol>
          <CCol col="1" class="border border-secondary ccol-header-center">
            <span>不可瀏覽</span>
          </CCol>
          <CCol col="1" class="border border-secondary ccol-header-center">
            <span>可瀏覽</span>
          </CCol>
          <CCol col="1" class="border border-secondary ccol-header-center">
            <span>可編輯</span>
          </CCol>
          <CCol col="1" class="border border-secondary ccol-header-center">
            <span>可瀏覽 可匯出</span>
          </CCol>
          <CCol col="1" class="border border-secondary ccol-header-center">
            <span>可編輯 可匯出</span>
          </CCol>
        </CRow>
        <div
          v-for="(item, index) in serviceGroupTeam.serviceFunctions"
          :key="item.serviceFunctionId"
        >
          <div :key="index">
            <CRow class="row justify-content-center">
              <CCol col="2" :class="{'border-left':true , 'border-top': moduleNameDisplay(item.serviceModuleName, index) ,'border-bottom': ccol(item.serviceModuleName, index), 'border-right':true ,'ccol-header-center':true}">
                <span>{{ moduleNameDisplay(item.serviceModuleName, index) }}</span>
              </CCol>
              <CCol col="4" class="border border-secondary ccol-header-center">
                <span>{{ item.name }}</span>
              </CCol>
              <CCol col="1" class="border border-secondary ccol-header-center">
                <el-radio
                  v-model="item.authorities"
                  label="0"
                  @change.native="setFunctionAuthorities(item, '0')"
                  ><span></span
                ></el-radio>
              </CCol>
              <CCol col="1" class="border border-secondary ccol-header-center">
                <el-radio
                  v-model="item.authorities"
                  label="1"
                  @change.native="setFunctionAuthorities(item, '1')"
                  ><span></span
                ></el-radio>
              </CCol>
              <CCol col="1" class="border border-secondary ccol-header-center">
                <el-radio
                  v-model="item.authorities"
                  label="1,2"
                  @change.native="setFunctionAuthorities(item, '1,2')"
                  ><span></span
                ></el-radio>
              </CCol>
              <CCol col="1" class="border border-secondary ccol-header-center">
                <el-radio
                  v-model="item.authorities"
                  label="1,3"
                  @change.native="setFunctionAuthorities(item, '1,3')"
                  ><span></span
                ></el-radio>
              </CCol>
              <CCol col="1" class="border border-secondary ccol-header-center">
                <el-radio
                  v-model="item.authorities"
                  label="1,2,3"
                  @change.native="setFunctionAuthorities(item, '1,2,3')"
                  ><span></span
                ></el-radio>
              </CCol>
            </CRow>
          </div>
        </div>
      </el-form-item>

      <!-- 新增按鈕 -->
      <el-form-item>
        <el-col align="center"
          ><el-button type="primary" @click="update">儲存</el-button></el-col
        >
      </el-form-item>
    </el-form>
    </el-card>
  </div>
</template>

<script>
  import API from "../../api/";
  import { Message } from "element-ui";
  import Vue from "vue";
  import _ from "lodash";

  export default {
    name: "GroupTeamEdit",
   
    data() {
      return {
        isLoading: false,
        serviceGroupTeam: {
          name: null,
          serviceFunctions: [],
        },
        rules: {
          name: [
            { required: true, message: "群組名稱必填", trigger: "blur" },
            { min: 1, max: 30, message: "長度在1-30個字符", trigger: "blur" },
          ],
        },
      };
    },
    watch: {
      clickTime: function(newVal) {
        this.openWindow();
      },
    },
    created() {
      this.openWindow();
    },
    methods: {
      /**
       * 開啟畫面時執行
       */
      openWindow() {
        // 1.移除校验结果
        this.resetForm("editForm");
        // 2.取得 群組數據
        this.getGroupTeamData(this.$route.query.editId);
      },
      getGroupTeamData(id) {
        if (id == null) return;
        this.isLoading = true;
        API.groupTeam.queryById(id).then((res) => {
          this.serviceGroupTeam = res.data;
          this.isLoading = false;
        });
      },
      // 設定 功能權限範圍
      setFunctionAuthorities(index, value) {
        for (let i = 0; i < this.serviceGroupTeam.serviceFunctions.length; i++) {
          if (this.serviceGroupTeam.serviceFunctions[i].id === index.id) {
            this.serviceGroupTeam.serviceFunctions[i].authorities = value;
          }
        }
      },
      /**
       * 更新
       * create by hao 2021-06-24
       */
      update() {
        this.$refs["editForm"].validate((valid) => {
          if (valid) {
            this.isLoading = true;
            API.groupTeam
              .update(this.$route.query.editId, this.serviceGroupTeam)
              .then((res) => {
                // 1. 顯示訊息
                Message({
                  message: "更新成功!",
                  type: "success",
                });
                 // 編輯結束,回到上一頁
                    this.$router.go(-1);

              })
              .catch((err) => {
                // 1. loading 結束
                this.isLoading = false;
              });
          } else {
            Message({
              message: "格式有誤，請重新檢查!",
              type: "warning",
            });
          }
        });
      },
      // 系統模組 名稱顯示
      moduleNameDisplay(serviceModuleName, index) {
        if (index === 0) return `${serviceModuleName}`;
        else {
          if (serviceModuleName === this.serviceGroupTeam.serviceFunctions[index - 1].serviceModuleName)
            return "";
          else return `${serviceModuleName}`;
        }
      },
      ccol(serviceModuleName, index) {
        if (index === this.serviceGroupTeam.serviceFunctions.length-1 ) return `${serviceModuleName}`;
        else {
          if (serviceModuleName === this.serviceGroupTeam.serviceFunctions[index + 1].serviceModuleName)
            return "";
          else return `${serviceModuleName}`;
        }
      },
      /**
       * el-form-item.vue的函數resetField將關於驗證相關的data數據置空，並將表單的各個字段設置為初始值；
       */
      resetForm(formName) {
        this.$nextTick(() => {
          this.$refs[formName].resetFields();
        });
      },
    },
  };
</script>

<style scoped>

.title {
  font-size: 18px;
  font-weight: bold;
}

.ccol-header-center {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
